import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  props: ['chartData', 'options'],
  mounted () {
    this.renderChart(this.chartData, this.options)
  },
  watch: {
    chartData: {
      handler (val) {
        this.renderChart(val, this.options)
      },
      deep: true
    },
    options: {
      handler (val) {
        this.renderChart(this.chartData, val)
      }
    }
  }
}
