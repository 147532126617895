<template>
  <div>
    <v-card class="pa-4">
      <v-row>
        <v-col cols="12">
          <h1>Sales By Channel Dashboard</h1>
        </v-col>
      </v-row>
      <v-row class="report-wrap">
        <v-col
          md="6"
          sm="12"
          cols="12">
          <div class="d-flex">
            <v-select
              v-model="brandSelected"
              label="เลือก Brand"
              :items="allBrand"
              outlined
              dense
              class="mr-2"
              hide-details />
          </div>
        </v-col>
        <v-col
          md="6"
          sm="12"
          cols="12"
          class="d-flex align-end">
          <date-picker v-model="dateSelected" />
          <v-btn
            class="ml-2"
            @click="fetch()">
            Go
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="pa-4 mt-8">
      <v-row>
        <v-col
          sm="6"
          cols="12">
          <base-material-stats-card
            color="purple darken-3"
            icon="mdi-sigma"
            title="All"
            :value="`${showNumberFormat(summaryAll.totalBillNet)} THB`"
            sub-text="All channel." />
        </v-col>
        <v-col
          sm="6"
          cols="12">
          <base-material-stats-card
            color="red darken-4"
            icon="mdi-store"
            title="POS"
            :value="`${showNumberFormat(summaryPos.totalBillNet)} THB`"
            sub-text="POS only." />
        </v-col>

        <v-col
          sm="6"
          cols="12">
          <base-material-stats-card
            color="orange"
            icon="mdi-web"
            title="Online"
            :value="`${showNumberFormat(summaryWebManualBot.totalBillNet)} THB`"
            sub-text="Web, Manaul, Chatbot." />
        </v-col>

        <v-col
          sm="6"
          cols="12">
          <base-material-stats-card
            color="indigo darken-2"
            icon="mdi-airplane"
            title="Inter"
            :value="`${showNumberFormat(summaryInterInter.totalBillNet)} THB`"
            sub-text="International Web, Bot, Manual." />
        </v-col>

        <v-col
          sm="6"
          cols="12">
          <base-material-stats-card
            color="#4CC764"
            icon="mdi-store-outline"
            title="LINE MyShop"
            :value="`${showNumberFormat(summaryLINEMyShop.totalBillNet)} THB`"
            sub-text="LINE MyShop only." />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          class="box-title d-flex align-center">
          <h2>ยอดขาย + Stock</h2>
          <v-icon
            class="ml-2"
            color="success"
            :loading="loading"
            @click="exportCsv">
            mdi-microsoft-excel
          </v-icon>
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="saleReportHeaders"
            :items="saleReport"
            :items-per-page="-1"
            :loading="loading"
            class="text-small"
            dense
            hide-default-footer>
            <template v-slot:[`item.totalPrice`]="{ item }">
              {{ item.totalPrice | showFullPriceFormat() }}
            </template>
            <template v-slot:[`item.stv`]="{ item }">
              {{ item.stv | showFullPriceFormat() }}
            </template>
            <template v-slot:[`item.stockOut`]="{ item }">
              {{ item.stockOut | showFullPriceFormat() }}
            </template>
            <template v-slot:[`item.stockOutLogoSeries`]="{ item }">
              {{ item.stockOutLogoSeries | showFullPriceFormat() }}
            </template>
            <template v-slot:[`item.stockIn`]="{ item }">
              {{ item.stockIn | showFullPriceFormat() }}
            </template>
            <template v-slot:[`item.stockInLogoSeries`]="{ item }">
              {{ item.stockInLogoSeries | showFullPriceFormat() }}
            </template>
            <template v-slot:[`item.astv`]="{ item }">
              {{ item.astv | showFullPriceFormat() }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <PosSaleReportChart
            ref="PosSaleReportChart"
            :brand="brandSelected" />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import NewMerlinProvider from '@/resources/NewMerlinProvider'
import { ExportToCsv } from 'export-to-csv'
import { getAuthDecode } from '@/assets/js/Authentication'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import DatesPicker from '../components/DatesPicker.vue'
import PosSaleReportChart from '../sections/PosSaleReportChart.vue'

const ProductAttributeService = new ProductAttributeProvider()
const NewMerlinService = new NewMerlinProvider()
export default {
  components: {
    PosSaleReportChart,
    DatePicker: DatesPicker
  },
  data () {
    return {
      loading: false,
      summary: [],
      brandSelected: 'all',
      allBrand: [],
      saleReportHeaders: [
        { text: 'สาขา', value: 'warehouse', width: 100 },
        { text: 'จำนวนบิล', value: 'countBill', align: 'right', width: 80 },
        { text: 'ยอดขาย', value: 'totalPrice', align: 'right', width: 150 },
        { text: 'Stock In Value', value: 'stockIn', align: 'right', width: 150 },
        { text: 'Stock In Value (logoseries)', value: 'stockInLogoSeries', align: 'right', width: 150 },
        { text: 'Stock Out Value', value: 'stockOut', align: 'right', width: 150 },
        { text: 'Stock Out Value (logoseries)', value: 'stockOutLogoSeries', align: 'right', width: 150 },
        { text: 'Ending Stock Value', value: 'astv', align: 'right', width: 200 }
      ],
      saleReport: [],
      dateSelected: [this.$dayjs().format(), this.$dayjs().format()]
    }
  },
  computed: {
    summaryPos () {
      const pos = this.summary.find((s) => s.id === 'pos')

      return { totalBillNet: pos?.totalBillNet || 0, countBill: pos?.countBill || 0 }
    },
    summaryWebManualBot () {
      const web = this.summary.find((s) => s.id === 'web')
      const manual = this.summary.find((s) => s.id === 'manual')
      const chatbot = this.summary.find((s) => s.id === 'chatbot')

      const totalBillNet = (web?.totalBillNet || 0) + (manual?.totalBillNet || 0) + (chatbot?.totalBillNet || 0)
      const countBill = (web?.countBill || 0) + (manual?.countBill || 0) + (chatbot?.countBill || 0)

      return { totalBillNet, countBill }
    },
    summaryLINEMyShop () {
      const lms = this.summary.find((s) => s.id === 'line_myshop')

      const totalBillNet = lms?.totalBillNet || 0
      const countBill = lms?.countBill || 0

      return { totalBillNet, countBill }
    },
    summaryInterInter () {
      const onlineInternational = this.summary.find((s) => s.id === 'online_international')
      const internationalManual = this.summary.find((s) => s.id === 'international_manual')
      const chatbotInternational = this.summary.find((s) => s.id === 'chatbot_international')

      const totalBillNet = (onlineInternational?.totalBillNet || 0)
            + (internationalManual?.totalBillNet || 0)
            + (chatbotInternational?.totalBillNet || 0)

      const countBill = (onlineInternational?.countBill || 0)
            + (internationalManual?.countBill || 0)
            + (chatbotInternational?.countBill || 0)

      return { totalBillNet, countBill }
    },
    summaryAll () {
      const totalBillNet = this.summaryPos.totalBillNet
        + this.summaryWebManualBot.totalBillNet
        + this.summaryInterInter.totalBillNet
        + this.summaryLINEMyShop.totalBillNet
      const countBill = this.summaryPos.countBill
        + this.summaryWebManualBot.countBill
        + this.summaryInterInter.countBill
        + this.summaryLINEMyShop.countBill

      return { totalBillNet, countBill }
    }
  },
  async mounted () {
    await this.getBrand()
    this.fetch()
  },
  methods: {
    async getBrand () {
      try {
        const { data } = await ProductAttributeService.getProductAttribute('brands', {
          limit: 999
        })

        this.allBrand = [
        {
          text: 'All',
          value: 'all'
        },
          ...data.results.map((brand) => ({
            text: brand.name,
            value: brand.name
          }))
        ]
      } catch (error) {
        console.error('getAttribute: ', error)
      }
    },
    exportCsv () {
      const date1 = this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format('DD-MM-YYYY')
      const date2 = this.$dayjs(this.dateSelected[1])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format('DD-MM-YYYY')
      const options = {
        filename: `sales_and_stock_${date1}-${date2}_${this.brandSelected}`,
        showLabels: false,
        useKeysAsHeaders: true
      }

      const data = []

      for (const re of this.saleReport) {
        const payload = {}
        for (const head of this.saleReportHeaders) {
          payload[head.text] = re[head.value] || ''
        }

        data.push(payload)
      }

      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(data)
    },
    async fetch () {
      this.summary = []
      this.saleReport = []
      this.$refs.PosSaleReportChart.fetchReport()
      await this.fetchReportSummary()
      await this.fetchReportSummaryWarehouse()
    },
    showNumberFormat (number = 0, tofix = 2) {
      if (number === null) return '-'
      return parseFloat(number).toFixed(tofix).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    async fetchReportSummary () {
      this.loading = true
      try {
        const { data } = await NewMerlinService.getReportSummary({
          brand: this.brandSelected,
          startDate: this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format(),
          endDate: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format()
        })
        this.summary = data
      } catch (err) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[error on fetch report]: ${err.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    addTableTail () {
      this.saleReport.push({
          id: 'inter',
          warehouse: 'International',
          countBill: this.summaryInterInter.countBill,
          totalPrice: this.summaryInterInter.totalBillNet,
          stockIn: null,
          stockOut: null,
          astv: null
      })
      this.saleReport.push({
          id: 'online',
          warehouse: 'Online',
          countBill: this.summaryWebManualBot.countBill,
          totalPrice: this.summaryWebManualBot.totalBillNet,
          stockIn: null,
          stockOut: null,
          astv: null
      })
      this.saleReport.push({
          id: 'LINE MyShop',
          warehouse: 'LINE MyShop',
          countBill: this.summaryLINEMyShop.countBill,
          totalPrice: this.summaryLINEMyShop.totalBillNet,
          stockIn: null,
          stockOut: null,
          astv: null
      })
      this.saleReport.push({
          id: 'all',
          warehouse: 'All',
          countBill: this.summaryAll.countBill,
          totalPrice: this.summaryAll.totalBillNet,
          stockIn: 'loading...',
          stockOut: 'loading...',
          astv: 'loading...'
      })
    },
    addSaleWarehouse () {
      const GW_STORE = '5e3548c2d32cb12606a34fb8'
      const auth = getAuthDecode()
      const authStore = auth?.store?.id || ''
      if (authStore !== GW_STORE) return

      const swhIndex = this.saleReport.findIndex((sr) => sr.id === 964)
      if (swhIndex) {
        this.saleReport.push({
          id: 964,
          warehouse: 'SaleWarehouse',
          countBill: 0,
          totalPrice: 0,
          stockIn: 'loading...',
          stockInLogoSeries: 'loading...',
          stockOutLogoSeries: 'loading...',
          stockOut: 'loading...',
          astv: 'loading...',
          percentToTarget: 'loading...'
        })
      }

      const kswhIndex = this.saleReport.findIndex((sr) => sr.id === 1072)
      if (kswhIndex) {
        this.saleReport.push({
          id: 1072,
          warehouse: 'Kids - Salewarehouse',
          countBill: 0,
          totalPrice: 0,
          stockIn: 'loading...',
          stockInLogoSeries: 'loading...',
          stockOutLogoSeries: 'loading...',
          stockOut: 'loading...',
          astv: 'loading...',
          percentToTarget: 'loading...'
        })
      }
    },
    async fetchReportSummaryWarehouse () {
      this.loading = true
      try {
        const { data } = await NewMerlinService.getReportWarehouse({
          brand: this.brandSelected,
          startDate: this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format(),
          endDate: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format()
        })

        this.saleReport = data.map((d) => ({
          id: d.id,
          warehouse: d.warehouseName,
          countBill: d.countBill,
          totalPrice: d.totalBillNet,
          stockIn: 'loading...',
          stockInLogoSeries: 'loading...',
          stockOutLogoSeries: 'loading...',
          stockOut: 'loading...',
          astv: 'loading...',
          percentToTarget: 'loading...'
        }))
        this.addSaleWarehouse()
        this.addTableTail()
        this.fetchReportStockInWarehouse()
        this.fetchReportStockOutWarehouse()
        this.fetchReportStockSnapshotWarehouse()
      } catch (err) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[error on fetch report]: ${err.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async fetchReportStockInWarehouse () {
      try {
        const { data } = await NewMerlinService.getStockInWarehouse({
          brand: this.brandSelected,
          startDate: this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format(),
          endDate: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format()
        })
        let sumStockIn = 0
        let sumStockInLogoSeries = 0
        this.saleReport = this.saleReport.map((d) => {
          if (d.stockIn === null) {
            return {
              ...d,
              stockIn: null
            }
          }
          const stock = data.filter((df) => df.logoseries === 'no').find((dd) => dd.warehouseId === d.id)
          const stockLogoSeries = data.filter((df) => df.logoseries === 'yes').find((dd) => dd.warehouseId === d.id)
          sumStockIn += stock?.sumStockValue || 0
          sumStockInLogoSeries += stockLogoSeries?.sumStockValue || 0
          return {
            ...d,
            stockIn: stock?.sumStockValue || 0,
            stockInLogoSeries: stockLogoSeries?.sumStockValue || 0,
            astv: 'loading...',
            percentToTarget: 'loading...'
          }
        })

        this.saleReport[this.saleReport.length - 1].stockIn = sumStockIn
        this.saleReport[this.saleReport.length - 1].stockInLogoSeries = sumStockInLogoSeries
      } catch (err) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[error on fetch report]: ${err.message}`,
          type: 'error'
        })
      }
    },
    async fetchReportStockOutWarehouse () {
      try {
        const { data } = await NewMerlinService.getStockOutWarehouse({
          brand: this.brandSelected,
          startDate: this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format(),
          endDate: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format()
        })
        let sumStockOut = 0
        let sumStockOutLogoSeries = 0
        this.saleReport = this.saleReport.map((d) => {
          if (d.stockOut === null) {
            return {
              ...d,
              stockOut: null
            }
          }
          const stock = data.filter((df) => df.logoseries === 'no').find((dd) => dd.warehouseId === d.id)
          const stockLogoSeries = data.filter((df) => df.logoseries === 'yes').find((dd) => dd.warehouseId === d.id)
          sumStockOut += stock?.sumStockValue || 0
          sumStockOutLogoSeries += stockLogoSeries?.sumStockValue || 0
          return {
            ...d,
            stockOut: stock?.sumStockValue || 0,
            stockOutLogoSeries: stockLogoSeries?.sumStockValue || 0,
            astv: 'loading...',
            percentToTarget: 'loading...'
          }
        })

        this.saleReport[this.saleReport.length - 1].stockOut = sumStockOut
        this.saleReport[this.saleReport.length - 1].stockOutLogoSeries = sumStockOutLogoSeries
      } catch (err) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[error on fetch report]: ${err.message}`,
          type: 'error'
        })
      }
    },
    async fetchReportStockSnapshotWarehouse () {
      try {
        const { data } = await NewMerlinService.getStockValWarehouse({
          brand: this.brandSelected,
          date: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format()
        })
        let sumAstv = 0
        this.saleReport = this.saleReport.map((d) => {
          const stock = data.find((dd) => dd.id === d.id)
          if (d.astv === null) {
            return {
              ...d,
              astv: null
            }
          }
          sumAstv += stock?.sumStockValue || 0
          return {
            ...d,
            astv: stock?.sumStockValue || 0
          }
        })
        this.saleReport[this.saleReport.length - 1].astv = sumAstv
      } catch (err) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[error on fetch report]: ${err.message}`,
          type: 'error'
        })
      }
    }
  }
}
</script>

<style scoped>
.number-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  border: 1px solid #cfcfcf;
  position: relative;
}
.number-box .box-title {
  position: absolute;
  font-size: 14px;
  padding: 0 5px;
  top: -10px;
  left: 5px;
  background-color: #fff;
}
</style>
