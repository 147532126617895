import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class NewMerlinProvider extends HttpRequest {
  getReportSummary (query) {
    this.setHeader(getAuthToken())
    return this.get('new-merlin-report/summary', query)
  }

  getReportWarehouse (query) {
    this.setHeader(getAuthToken())
    return this.get('new-merlin-report/summary-warehouse', query)
  }

  getReportWarehouseChannel (query) {
    this.setHeader(getAuthToken())
    return this.get('new-merlin-report/summary-warehouse-channel', query)
  }

  getStockInWarehouse (query) {
    this.setHeader(getAuthToken())
    return this.get('new-merlin-report/stock-in-warehouse', query)
  }

  getStockOutWarehouse (query) {
    this.setHeader(getAuthToken())
    return this.get('new-merlin-report/stock-out-warehouse', query)
  }

  getStockValWarehouse (query) {
    this.setHeader(getAuthToken())
    return this.get('new-merlin-report/stock-snapshot-warehouse', query)
  }
}

export default NewMerlinProvider
