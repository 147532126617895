<template>
  <line-chart
    :chart-data="amountOrderOfSpentRange.dataCollection"
    :options="amountOrderOfSpentRange.chartOptions" />
</template>

<script>

import NewMerlinProvider from '@/resources/NewMerlinProvider'
import LineChart from '../charts/LineChart'

const NewMerlinService = new NewMerlinProvider()

export default {
  components: {
    LineChart
  },
  props: {
    brand: {
      type: String,
      default: 'all'
    }
  },
  data () {
    return {
      amountOrderOfSpentRange: {
        dataCollection: null,
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: 'POS Sale Report',
            position: 'top'
          }
        }
      },
      colors: [
        {
          backgroundColor: '#F44336',
          borderColor: '#B71C1C'
        },
        {
          backgroundColor: '#E91E63',
          borderColor: '#880E4F'
        },
        {
          backgroundColor: '#9C27B0',
          borderColor: '#4A148C'
        },
        {
          backgroundColor: '#EA80FC',
          borderColor: '#AA00FF'
        },
        {
          backgroundColor: '#673AB7',
          borderColor: '#311B92'
        },
        {
          backgroundColor: '#3F51B5',
          borderColor: '#1A237E'
        },
        {
          backgroundColor: '#C5CAE9',
          borderColor: '#5C6BC0'
        },
        {
          backgroundColor: '#546E7A',
          borderColor: '#546E7A'
        },
        {
          backgroundColor: '#2196F3',
          borderColor: '#0D47A1'
        },
        {
          backgroundColor: '#00BCD4',
          borderColor: '#0097A7'
        },
        {
          backgroundColor: '#009688',
          borderColor: '#004D40'
        },
        {
          backgroundColor: '#4CAF50',
          borderColor: '#1B5E20'
        },
        {
          backgroundColor: '#81C784',
          borderColor: '#388E3C'
        },
        {
          backgroundColor: '#FFEB3B',
          borderColor: '#FDD835'
        },
        {
          backgroundColor: '#F9A825',
          borderColor: '#F57F17'
        },
        {
          backgroundColor: '#FF5722',
          borderColor: '#BF360C'
        },
        {
          backgroundColor: '#795548',
          borderColor: '#3E2723'
        },
        {
          backgroundColor: '#9E9E9E',
          borderColor: '#9E9E9E'
        },
        {
          backgroundColor: '#F50057',
          borderColor: '#F50057'
        }
      ]
    }
  },
  mounted () {
    this.fetchReport()
  },
  methods: {
    async fetchReport () {
      const rawData = []
      for (let i = 11; i >= 0; i--) {
        const startDate = this.$dayjs()
                            .subtract(i, 'month')
                            .date(1)
                            .hour(0)
                            .minute(0)
                            .second(0)

        const endDate = this.$dayjs()
                        .date(1)
                        .hour(0)
                        .minute(0)
                        .second(0)
                        .subtract(i - 1, 'month')
        rawData.push({
          data: NewMerlinService.getReportWarehouse({
            brand: this.brand,
            startDate: startDate.format(),
            endDate: endDate.format()
          }),
          label: startDate.format('MMM')
        })
      }

      const results = (await Promise.all(rawData.map((r) => r.data))).map((r) => r.data)

      const allWarehouse = []
      results.forEach((r) => {
        for (const w of r) {
          const index = allWarehouse.findIndex((aw) => aw.id === w.id)
          if (index === -1) {
            allWarehouse.push({
              id: w.id,
              name: w.warehouseName
            })
          }
        }
      })

      this.amountOrderOfSpentRange.dataCollection = {
        labels: [],
        datasets: []
      }

      this.amountOrderOfSpentRange.dataCollection.labels = rawData.map((each) => each.label)
      for (const [index, wh] of allWarehouse.entries()) {
        const data = []
        results.forEach((r) => {
          const whr = r.find((rr) => rr.id === wh.id)
          if (whr) {
            data.push(whr.totalBillNet)
          } else {
            data.push(0)
          }
        })
        const colorIndex = index % this.colors.length
        this.amountOrderOfSpentRange.dataCollection.datasets.push({
          label: wh.name,
          data,
          fill: false,
          backgroundColor: this.colors[colorIndex].backgroundColor,
          borderColor: this.colors[colorIndex].borderColor
        })
      }
    }
  }
}
</script>
