<template>
  <v-menu
    v-model="datePickerMenu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateRange[0]"
        class="mr-2"
        :label="$t('fields.DateRange')"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        hide-details
        dense
        solo
        v-on="on" />
      <v-text-field
        v-model="dateRange[1]"
        :label="$t('fields.DateRange')"
        readonly
        v-bind="attrs"
        hide-details
        dense
        solo
        v-on="on" />
    </template>
    <v-date-picker
      v-model="dateRange[0]"
      class="mr-2 elevation-3">
      <v-spacer />
      <v-btn
        text
        small
        @click="dateRangeClose()">
        {{ $t('fields.close') }}
      </v-btn>
      <v-btn
        text
        small
        @click="dateRangeSubmit()">
        {{ $t('fields.submit') }}
      </v-btn>
    </v-date-picker>
    <v-date-picker
      v-model="dateRange[1]"
      class="elevation-3">
      <v-spacer />
      <v-btn
        text
        small
        @click="dateRangeClose()">
        {{ $t('fields.close') }}
      </v-btn>
      <v-btn
        text
        small
        @click="dateRangeSubmit()">
        {{ $t('fields.submit') }}
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      datePickerMenu: false,
      dateRange: ['2019-09-10', '2019-09-20']
    }
  },
  created () {
    this.initDefaultDate()
  },
  methods: {
    initDefaultDate () {
      const startDate = this.$dayjs().format('YYYY-MM-DD')
      const endDate = this.$dayjs().format('YYYY-MM-DD')
      this.dateRange = [startDate, endDate]
    },
    dateRangeSubmit () {
      if (this.dateRange[0] > this.dateRange[1]) {
        this.dateRange = [this.dateRange[1], this.dateRange[0]]
      }

      if (this.dateRange[0] && this.dateRange.length === 1) {
        this.dateRange = [this.dateRange[0], this.dateRange[0]]
      }

      this.$emit('input', [
        this.$dayjs(this.dateRange[0]).format('YYYY-MM-DD'),
        this.$dayjs(this.dateRange[1]).format('YYYY-MM-DD')
      ])
      this.datePickerMenu = false
    },
    dateRangeClose () {
      this.datePickerMenu = false
    }
  }
}
</script>

<style  scoped>

.date-picker-input {
  height: 28px;
}
</style>
